<template>
  <v-dialog :value="quoteModal" fullscreen persistent>
    <v-dialog
      @click:outside="closeSearchItemDialog"
      :value="searchItemDialog"
      hide-overlay
      width="500px"
    >
      <v-card height="300px">
        <search-item
          v-if="searchItemDialog"
          :searchString="searchString"
          :autoFocus="true"
          @setSearchString="setSearchString"
          @get-results="addQuoteItem"
          @clear-input="clearItem"
        ></search-item>
      </v-card>
    </v-dialog>
    <v-card style="margin-bottom:120px !important">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <div class="client-info mt-2">
              <fieldset class="fieldset text-left">
                <legend class="legend">Klant</legend>
                <div class="h1">
                  {{ thisQuote.name }}
                  <v-icon
                    class="edit-client-icon"
                    @click="openEditClientModal"
                    color="primary"
                    large
                    >mdi-pencil-circle</v-icon
                  >
                </div>
                <div class="subtitle-1">
                  <template v-if="thisQuote.client.shipping">
                    {{ thisQuote.client.shippingAddress }}  <br>
                    {{thisQuote.client.shippingPostalcode}},
                    {{thisQuote.client.shippingCity}} <br>
                    {{thisQuote.client.shippingCountry}}
                  </template>
                  <template v-else>   
                  {{ thisQuote.client.address }} <br>
                  {{ thisQuote.client.postalcode }},
                  {{ thisQuote.client.city }} <br>
                  {{ thisQuote.client.country}}
                  </template>
                  <br />
                  {{ thisQuote.client.phone }} <br />
                  {{ thisQuote.client.mobile }}
                </div>
              </fieldset>
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <v-row no-gutters class="mt-5">
              <v-col sm="4"></v-col>
              <v-col cols="12" sm="8" class="d-flex justify-space-around">
                <div class="small-text-field">
                </div>
                <div class="small-text-field">
                  <date-picker label="Leverdatum" :value="deliveryDate" :dateFormatted="deliveryDateFormatted" @changeDate="changeDeliveryDate"></date-picker>
                </div>
                <div class="small-text-field">
                  <v-menu
                    ref="menu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="thisQuote.deliveryTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="thisQuote.deliveryTime"
                        label="Levertijd"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timeMenu"
                      v-model="thisQuote.deliveryTime"
                      full-width
                      format="24hr"
                      @click:minute="$refs.menu.save(thisQuote.deliveryTime)"
                    ></v-time-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="4"></v-col>
              <v-col cols="12" sm="8" class="d-flex justify-space-around">
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">
                      Offertenr
                    </legend>
                    {{ thisQuote.quoteNumber }}
                  </fieldset>
                </div>
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">
                      Ordernr
                    </legend>
                    {{ thisQuote.orderNumber }}
                  </fieldset>
                </div>
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">
                      Status
                    </legend>
                    <v-chip
                      class="fieldset-status"
                      @click="incQuoteStatus"
                      :color="quoteStatuses[thisQuote.status].color"
                      >{{ quoteStatuses[thisQuote.status].text }}</v-chip
                    >
                  </fieldset>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          dense
          :headers="itemHeaders"
          class="elevation-1"
          locale="nl"
          :items="thisQuote.items"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.name`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.name"
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ props.item.name }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.name"
                  @change="updateQuoteItem(props.item)"
                  label="Naam"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.description`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.description"
              large
              persistent
              cancel-text="Annuleren"
              save-text="Opslaan"
              @save="saveItem"
              @open="cachedDescription = props.item.description"
              @cancel="props.item.description = cachedDescription"
              @close="cachedDescription = ''"
            >
              <span v-html="props.item.description"></span>
              <template v-slot:input>
                <div @keydown.enter.stop>
                  <vue-editor v-model="props.item.description"></vue-editor>
                </div>
              </template>
            </v-edit-dialog>
          </template>
          <vue-editor></vue-editor>
          <template v-slot:[`item.amount`]="props">
            <v-edit-dialog
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ props.item.amount }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.amount"
                  @focus="$event.target.select()"
                  @change="updateQuoteItem(props.item)"
                  label="Aantal"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.unitAmount`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.unitAmount"
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ props.item.unitAmount }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.unitAmount"
                  @focus="$event.target.select()"
                  @change="updateQuoteItem(props.item)"
                  label="Inhoud"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.price`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.price"
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ toCurrency(props.item.price) }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.price"
                  @focus="$event.target.select()"
                  @change="updateQuoteItem(props.item)"
                  label="Prijs"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.VAT`]="{ item }"> {{ item.VAT }}% </template>
          <template v-slot:[`item.netSubtotal`]="{ item }">{{
            toCurrency(item.netSubtotal)
          }}</template>
          <template v-slot:[`item.grossSubtotal`]="{ item }">{{
            toCurrency(item.grossSubtotal)
          }}</template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteQuoteItem(item)">mdi-delete</v-icon>
            <!-- <v-icon @click="updateQuoteItem(item, true)"
                >mdi-do-not-disturb--outofstock</v-icon TODO: add this icon
              > -->
          </template>
        </v-data-table>
        <v-row no-gutters justify="center">
          <div>
            <v-btn @click="openSearchItemDialog" color="primary">
              <h1>+</h1>
            </v-btn>
          </div>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <table>
              <tr>
                <td>Aantal:</td>
                <td>
                  {{ thisQuote.items.length }}
                </td>
              </tr>
              <tr>
                <td>Aangemaakt:&nbsp;</td>
                <td>
                  {{ toDateTimeString(thisQuote.created) }}
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" sm="4"></v-col>
          <v-col cols="12" sm="4"></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="pr-5">
            <fieldset class="fieldset">
              <legend class="legend">Opmerkingen</legend>
              <textarea v-model="thisQuote.notes"></textarea>
            </fieldset>
          </v-col>
          <v-col cols="12" sm="4">
            <fieldset class="fieldset" v-if="thisQuote.request">
              <legend class="legend">Offerteaanvraag</legend>
              <textarea v-model="thisQuote.request"></textarea>
            </fieldset>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex justify-space-around flex-wrap">
            <div class="small-text-field">
              <fieldset class="fieldset">
                <legend class="legend">Totaal excl.</legend>
                {{ toCurrency(quoteNetTotal) }}
              </fieldset>
            </div>
            <div class="small-text-field">
              <fieldset class="fieldset">
                <legend class="legend">Totaal incl.</legend>
                {{ toCurrency(quoteGrossTotal) }}
              </fieldset>
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="pr-5">
              <fieldset v-if="thisQuote.signature" class="fieldset">
                <legend class="legend">Handtekening</legend>
               <img :src="thisQuote.signature" width="200">
              </fieldset>
              <fieldset v-if="thisQuote.allergies" class="fieldset">
                <legend class="legend">Allergieën</legend>
               {{thisQuote.allergies}}
              </fieldset>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-footer fixed height="120">
          <v-row no-gutters>
            <v-col cols="12" sm="2"></v-col>
            <v-col cols="12" sm="7" class="d-flex justify-center flex-wrap">
              <v-btn-toggle class="d-flex flex-wrap">
                <v-btn
                  @click="openPrintQuoteModal"
                  class="d-none d-sm-flex"
                  color="primary"
                  >Afdrukken</v-btn
                >
                <v-btn
                  @click="openEmailQuoteModal"
                  class="d-none d-sm-flex"
                  color="primary"
                  >Mailen</v-btn
                >
                <v-btn
                  class="d-none d-sm-flex"
                  color="primary"
                  @click="openClientModal"
                  >Rekening</v-btn
                >
                <v-btn color="primary" @click="deleteQuote(thisQuote)"
                  >Verwijder</v-btn
                >
                <v-btn color="primary" @click="closeQuoteModal">Annuleer</v-btn>
                <v-btn color="primary" @click="updateQuote">Opslaan</v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex align-start justify-center">
              <span class="text-h6"
                >Totaal incl.: {{ toCurrency(quoteGrossTotal) }}</span
              >
            </v-col>
          </v-row>
        </v-footer>
      </v-card-actions>
    </v-card>
    <client-modal
      v-if="clientModal"
      :clientModal="clientModal"
      :client="client"
      :tabNumber="3"
      @insert-client="insertClient"
      @delete-client="deleteClient"
      @close-client-modal="closeClientModal"
    ></client-modal>
    <edit-client-modal
      v-if="editClientModal"
      :editClientModal="editClientModal"
      :client="client"
      :order="quote"
      @update-client="updateClient"
      @close-edit-client-modal="closeEditClientModal"
    ></edit-client-modal>
  </v-dialog>
</template>
<script>
import { toCurrency, toDateTimeString } from "../js/functions.js"
import Big from "big.js"
import clientModal from "./clientModal"
import searchItem from "./searchItem"
import { VueEditor } from "vue2-editor"
import editClientModal from "@/components/editClientModal.vue"
import datePicker from '@/components/datePicker.vue';
export default {
  name: "quoteModal",
  props: {
    quote: Object,
    quoteModal: Boolean
  },
  data() {
    return {
      thisQuote: JSON.parse(JSON.stringify(this.quote)),
      deliveryDate: new Date(this.quote.deliveryDate).toISOString().split("T")[0],
      deliveryDateFormatted: this.formatDate(
        new Date(this.quote.deliveryDate).toISOString().split("T")[0]
      ),
      searchString: "",
      client: {},
      clientModal: false,
      datePicker: false,
      searchItemDialog: false,
      itemHeaders: [
        { text: "Naam", value: "name" },
        { text: "Omschrijving", value: "description" },
        { text: "Aantal", value: "amount" },
        { text: "Eenheid", value: "unit" },
        { text: "Inhoud", value: "unitAmount" },
        { text: "Prijs", value: "price" },
        { text: "BTW", value: "VAT" },
        { text: "Subtotaal", value: "netSubtotal" },
        { text: "Totaal incl.", value: "grossSubtotal" },
        { text: "", value: "actions" }
      ],
      quoteStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "Nieuw",
          length: 0
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling",
          length: 0
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Goedgekeurd",
          length: 0
        }
      ],
      timeMenu: false,
      editClientModal: false,
      cachedDescription: ""
    }
  },
  methods: {
    changeToDot() {
      return 10
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    changeDeliveryDate(date){
      this.deliveryDate = date
      this.thisQuote.deliveryDate = date
      this.deliveryDateFormatted = this.formatDate(date)
    },
    setSearchString(val) {
      this.searchString = val
    },
    clearItem() {
      this.searchString = ""
    },
    toDateTimeString(val) {
      return toDateTimeString(val)
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    updateQuote() {
      let noZero = false
      let confirmed = true
      this.thisQuote.items.forEach(item => {
          if (item.amount == 0) noZero = true
      })
      if (noZero) {
        confirmed = confirm(
          "Er zijn nog artikelen die op 0 staan. Wilt u doorgaan?"
        )
      }
      if (confirmed) {
        let quoteVAT
        if (this.thisQuote.client.export) {
          quoteVAT = [{ name: "Geen", amount: 0, total: this.quoteNetTotal }]
        } else {
          quoteVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
          for (let VAT of quoteVAT) {
            VAT.total = this.thisQuote.items
              .filter(item => VAT.amount === item.VAT)
              .reduce((acc, cur) => {
                if (cur.VAT == 0)
                  return (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100
                else return (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100
              }, 0)
          }
        }
        let VAT = quoteVAT.filter(VAT => parseFloat(VAT.total) != 0)
        this.thisQuote.VAT = VAT
        this.thisQuote.totalVAT = this.thisQuote.items
          .filter(item => item.VAT > 0)
          .reduce((acc, cur) => acc + cur.totalVAT, 0)
        let totalWeight = this.thisQuote.items.reduce(
          (acc, cur) => acc + cur.totalWeight,
          0
        )
        this.thisQuote.totalWeight = totalWeight
        this.thisQuote.debit = this.quoteGrossTotal
        let date = new Date(this.deliveryDate)
        let isoDate = date.toISOString().split("T")[0]
        this.thisQuote.deliveryDate = isoDate
        this.thisQuote.totalProfit = this.totalProfit
        this.thisQuote.netTotal = this.quoteNetTotal
        this.thisQuote.totalVAT = this.totalVAT
        this.thisQuote.grossTotal = this.quoteGrossTotal
        this.thisQuote.totalPackages = this.totalPackages
        this.$emit("update-quote", this.thisQuote)
        this.closeQuoteModal()
        return true
      }
      return false
    },
    insertClient() {},
    deleteClient() {},
    async openClientModal() {
      this.client = this.quote.client
      this.clientModal = true
    },
    closeClientModal() {
      this.clientModal = false
      this.client = {}
    },
    openSearchItemDialog() {
      this.searchItemDialog = true
    },
    closeSearchItemDialog() {
      this.searchString = ""
      this.searchItemDialog = false
    },
    commaToDot(val) {
      let string = val + ""
      let replace = string.replace(/,/g, ".")
      val = parseFloat(replace)
      return val
    },
    addQuoteItem(item) {
      let found = item.fixedPrices.find(
        fixed => fixed.clientNumber == this.thisQuote.clientNumber
      )
      if (found) {
        item.price = found.price
      }
      let d = new Date(Date.now()).toISOString().split("T")
      d = d[0].split("-")
      let lotNumber = `${d[0].substr(-2)}/${d[1]}${d[2]}`
      item.lotNumber = item.lotNumber || lotNumber
      item.totalVAT = 0
      this.thisQuote.items.push(item)
      this.closeSearchItemDialog()
    },
    updateQuoteItem(item, outOfStock) {
      item.amount =
        typeof item.amount === "string"
          ? item.amount.replace(",", ".")
          : item.amount.toString().replace(",", ".")
      item.unitAmount =
        typeof item.unitAmount === "string"
          ? item.unitAmount.replace(",", ".")
          : item.unitAmount.toString().replace(",", ".")
      item.price =
        typeof item.price === "string"
          ? item.price.replace(",", ".")
          : item.price.toString().replace(",", ".")
      try {
        if (outOfStock) {
          item.amount = 0
          item.status = 2
        }
        item.amount = parseFloat(item.amount)
        item.unitAmount = parseFloat(item.unitAmount)
        item.price = parseFloat(item.price)
        let amount = Big(item.amount)
        let price = Big(item.price)
        let unitAmount = Big(item.unitAmount)
        let packages = Big()
        let totalWeight = Big()
        let netSubtotal = Big()
        let totalVAT = Big()
        let grossSubtotal = Big()
        item.VAT = this.thisQuote.client.export ? 0 : item.VAT
        let itemVAT = Big(item.VAT).div(100)
        packages =
          item.unit == "kg"
            ? amount.div(item.packageAmount).round(undefined, 3)
            : amount
        totalWeight =
          item.unit == "kg" ? amount : amount.times(item.unitAmount).round(2)
        netSubtotal =
          item.unit == "kg"
            ? amount.times(price).round(2)
            : amount
                .times(price)
                .times(unitAmount)
                .round(2)
        if (this.quote.client.includeVAT)
          netSubtotal = netSubtotal.div(
            Big(item.VAT)
              .plus(100)
              .div(100)
          )
        console.log(netSubtotal.toNumber())
        totalVAT = netSubtotal.times(itemVAT)
        grossSubtotal = netSubtotal.plus(totalVAT)
        item.packages = packages.toNumber()
        item.totalWeight = totalWeight.toNumber()
        item.netSubtotal = netSubtotal.toNumber()
        item.totalVAT = totalVAT.toNumber()
        item.grossSubtotal = grossSubtotal.toNumber()
        item.totalCost =
          item.unit == "kg"
            ? item.amount * item.cost
            : item.amount * item.unitAmount * item.cost
        item.totalProfit = item.netSubtotal - item.totalCost
      } catch (e) {
        console.log(e.message)
      }
    },
    saveItem() {},
    cancelItem() {},
    openItem() {},
    closeItem() {},
    deleteQuoteItem(item) {
      for (let i = 0; i < this.thisQuote.items.length; i++) {
       if (this.thisQuote.items[i]._id === item._id && this.thisQuote.items[i].name === item.name && this.thisQuote.items[i].price === item.price && this.thisQuote.items[i].amount === item.amount) {
          this.thisQuote.items.splice(i, 1)
          return
        }
      }
    },
    closeQuoteModal() {
      this.$emit("close-quote-modal")
    },
    incQuoteStatus() {
      if (this.thisQuote.status < 3) {
        this.thisQuote.status =
          this.thisQuote.status < 2 ? this.thisQuote.status + 1 : 0
      }
    },
    deleteQuote(quote) {
      this.$emit("delete-quote", quote)
    },
    openPrintQuoteModal() {
      let confirmed = this.updateQuote()
      if (confirmed) this.$emit("open-print-quote-modal", this.thisQuote)
    },
    openEmailQuoteModal() {
      let confirmed = this.updateQuote()
      if (confirmed) this.$emit("open-email-quote-modal", this.thisQuote)
    },
    openEditClientModal() {
      this.editClientModal = true
      this.client = this.quote.client
    },
    closeEditClientModal() {
      this.editClientModal = false
    },
    updateClient(client) { //TODO: update client only basic info
      this.thisQuote.client = client
      this.thisQuote.clientNumber = client.clientNumber
      this.thisQuote.name = client.name
      this.closeEditClientModal()
    }
  },
  computed: {
    items() {
      return this.$store.state.items
    },
    totalProfit() {
      return this.thisQuote.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.totalProfit) * 100) / 100,
        0
      )
    },
    quoteNetTotal() {
      return this.thisQuote.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100,
        0
      )
    },
    totalVAT() {
      return this.thisQuote.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100,
        0
      )
    },
    totalPackages() {
      return this.thisQuote.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.packages) * 100) / 100,
        0
      )
    },
    quoteGrossTotal() {
      return (
        (parseFloat(this.quoteNetTotal) * 100 +
          parseFloat(this.totalVAT) * 100) /
        100
      )
    },
    statuses() {
      return this.$store.state.settings.quoteStatuses
    }
  },
  components: {
    searchItem,
    clientModal,
    VueEditor,
    editClientModal,
    datePicker
  }
}
</script>
<style scoped>
input {
  background: none;
  font-size: 1.1em;
  border: none;
}
input:focus {
  background: white;
  font-size: 1.1em;
  color: #000;
}
.buttons {
  width: 25px;
  height: 25px;
}
.number {
  width: 60px;
}
.amount {
  width: 60px;
  text-align: right;
}
.status {
  height: 20px;
  vertical-align: top;
}
.small-text-field {
  width: 33%;
  min-height: 70px;
}
.fieldset {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  min-height: 70px;
  padding: 5px;
  font-size: 1.2rem;
}
.fieldset textarea {
  width: 100%;
  height: 200px;
  border: none;
  resize: none;
  font-size: 1.2rem;
}
.fieldset-status {
  margin-top: -5px;
}
.legend {
  text-align: left;
  margin-left: 5px;
  padding-right: 3px;
  font-size: 13px;
  font-weight: 400;
}
.edit-client-icon {
  position: absolute;
  right: 0;
  top: 14px;
}
.client-info {
  position: relative;
}
</style>
